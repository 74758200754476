import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Details from './Details'
import Home from './Home'

const AllRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/details' element={<Details />}></Route>
    </Routes>
  )
}

export default AllRoutes