import React, { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../Redux/action";
import { Box, Button } from "@chakra-ui/react";
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const data = useSelector((store) => store.data);
  const [filterdata, setFilterdata] = useState("");

  if (filterdata === "low") {
    data.sort((a, b) => a.population - b.population);
  } else if (filterdata === "high") {
    data.sort((a, b) => b.population - a.population);
  }

  const getdata = () => {
    axios.get(`https://restcountries.com/v3.1/all`).then((res) => {
      //   console.log(res.data);

      dispatch(getData(res.data));
    });
  };

  useEffect(() => {
    getdata();
  }, []);
console.log(data)
  const handleSort = (e) => {
    let str = e.target.value;
    setFilterdata(e.target.value)

    // if (str === "low") {
    //   data.sort((a, b) => a.population - b.population);
    // } else if (str === "high") {
    //   data.sort((a, b) => b.population - a.population);
    // }
  };

  return (
    <div>
      <div>
        <select
          onChange={(e) => {
            axios
              .get(`https://restcountries.com/v3.1/region/${e.target.value}`)
              .then((res) => {
                console.log(res.data);

                dispatch(getData(res.data));
              });
          }}
        >
          <option value={""}>Filter by Region</option>
          <option value={"asia"}>Asia</option>
          <option value={"africa"}>Africa</option>
          <option value={"americas"}>Americas</option>
          <option value={"europe"}>Europe</option>
          <option value={"oceania"}>Oceania</option>
        </select>

        {/* sort */}
        <select onChange={handleSort}>
          <option value={""}>Sort by Population</option>
          <option value={"low"}>Asc</option>
          <option value={"high"}>Dsce</option>
        </select>
      </div>
      <Box
        w={"90%"}
        m={"auto"}
        display={"grid"}
        gridTemplateRows={"auto"}
        gridTemplateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={4}
        justifyContent="center"
      >
        {data.map((ele, i) => (
          <Box border="1px solid" p={2} key={i}>
            <img src={ele.flags.png} alt={ele.flags.alt} />
            <div>
              <h4>{ele.name.common}</h4>
              <p>Population:{ele.population}</p>
              <p>Region:{ele.region}</p>
              <p>Capital:{ele.capital}</p>
            </div>

            <Button onClick={onOpen}>More Details</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <h6>Native-name: {ele.name.common}</h6>
                  <h6>Sub-Region: {ele.subregion}</h6>
                  <h6>Currency : {ele.currencies }</h6>
                  <h6>Language : {ele.Languages }</h6>
                  <h6 style={{ color: "black" }}>
                    Borders:{ele.borders ? ele.borders.join(" ") : ""}
                  </h6>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Home;
